import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Delete from "./delete";
import Privacy from "./privacy";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="/privacy" element={<Privacy />} />
        {/*<Route path="/terms" element={<Terms />} />*/}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
