function Privacy(props) {
  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      <h1 style={{ textAlign: "center" }}>プライバシーポリシー</h1>
      <div>
        MetaBox株式会社（以下「当社」といいます）は、当社が提供するサービス（以下「本サービス」といいます）の遂行に際して、個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます）を定めます。
      </div>
      {/* 1 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          1. 収集する利用者情報及び収集方法
        </div>
        <div>
          本ポリシーにおいて、「ユーザー情報」とは、ユーザーを識別する情報、本サービスにおける行動履歴、その他ユーザーまたはその端末に関連して作成または蓄積される情報であって、本ポリシーに基づき当社が収集する情報を意味します。
          当社が本サービスにおいて収集する可能性のある利用者情報は、収集方法に応じて以下のとおりです。
        </div>
        <div>
          <div style={{ fontWeight: "bold", padding: "10px 0 0" }}>
            （１）利用者が提供する情報
          </div>
          <div>
            本サービスの利用にあたり、ユーザーが提供する情報は以下の通りです。
          </div>
          <div style={{ padding: "0 10px" }}>
            <div>・ 名前</div>
            <div>・ 電子メールアドレス</div>
            <div>
              ・
              クレジットカード情報、銀行口座情報、電子マネー情報、その他支払方法に関する情報
            </div>
            <div>・ 利用者の声紋を含む音声情報</div>
            <div>
              ・
              ユーザーが入力フォームまたは当社が指定する方法を通じて送信した情報
            </div>
          </div>
        </div>
        <div>
          <div style={{ fontWeight: "bold", padding: "10px 0 0" }}>
            （２）利用者が本サービスの利用に関連して本サービスが他のサービスと連携することを許可したことに基づき、他のサービスから提供される情報
          </div>
          <div>
            当社は、利用者が本サービスの利用に関連して、ソーシャルネットワーキングサービス等の他のサービス（以下「外部サービス」といいます）との連携を許可した場合、当該許可時に利用者が同意した条件に従い、外部サービスから以下の情報を収集することがあります。
          </div>
          <div style={{ padding: "0 10px" }}>
            <div>・ ユーザーが外部サービスに利用するID</div>
            <div>
              ・
              その他、ユーザーが外部サービスのプライバシー設定において、関連サービスへの開示を許可した情報。
            </div>
          </div>
        </div>
        <div>
          <div style={{ fontWeight: "bold", padding: "10px 0 0" }}>
            （３）利用者が本サービスを利用する過程で当社が自動的に収集する情報
          </div>
          <div>
            当社は、本サービスへのアクセスまたは利用に関して、以下の情報を収集することがあります。
          </div>
          <div style={{ padding: "0 10px" }}>
            <div>・ リファラー</div>
            <div> ・ IPアドレス</div>
            <div> ・ サーバーアクセスログに関する情報</div>
            <div> ・ デバイス情報</div>
            <div>
              ･ Cookie、ADID、IDFAその他の識別子（以下「Cookie等」といいます）
            </div>
          </div>
        </div>
      </div>
      {/* 2 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          2. 利用目的
        </div>
        <div>
          本サービスの提供におけるユーザー情報の具体的な利用目的は以下のとおりです。
        </div>
        <div>
          <div style={{ padding: "0 10px" }}>
            <div>
              （１）本サービスの提供、維持、保護および改善（本サービスへの登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、本サービスに係る支払手数料の計算等を含みますが、これらに限定されません）
            </div>
            <div>（２）ユーザーのトラフィックと行動の測定</div>
            <div>
              （３）バグの検出と修正、パフォーマンスの向上、ユーザーエクスペリエンスの向上
            </div>
            <div>（４）本サービス内に表示される出力を生成すること</div>
            <div>（５）広告の配布、表示及び効果の測定</div>
            <div>（６）本サービスに関するご案内およびお問い合わせへの対応</div>
            <div>
              （７）本サービスに関する当社の規則、規制またはポリシー（以下「規則」という）に違反する行為に対処するため。
            </div>
            <div>（８）この規則または関連事項の変更の通知。</div>
          </div>
        </div>
      </div>
      {/* 3 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          3 利用者情報の利用停止を求める方法
        </div>
        <div>
          3.1
          ユーザーは、本サービスに定める設定を選択することにより、当社に対してユーザー情報の全部または一部の収集または利用の停止を求めることができます。当社は、当該要請があった場合、当社の定めるルールに従って、速やかに収集または利用を停止するものとします。ただし、ユーザー情報の中には、本サービスを提供するために必要なものもあり、ユーザーが当社の定める手続きに従って本サービスの利用を停止しない限り、当社は当該情報の収集または利用を停止しません。
        </div>
      </div>
      {/* 4 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          4 外部への送信、第三者への提供、情報収集モジュール
        </div>
        <div>
          4.1
          以下の情報収集モジュールがサービスに組み込まれています。その結果、ユーザー情報はこれらのモジュールのプロバイダーに提供されます。ユーザーは、サービス
          インターフェイス内で提供されるオプションを使用して、各モジュールとの情報共有を拒否できます。
        </div>
      </div>
      {/* Googleアナリティクス */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          Googleアナリティクス
        </div>
        <div>
          <div>（１）情報収集モジュールの名称：Google Analytics</div>
          <div>（２）情報収集モジュールの提供者：Google LLC</div>
          <div>
            （３）提供されるユーザー情報：ユーザーの行動データ、デバイス情報等
          </div>
          <div>
            （４）提供の手段・方法：暗号化通信により自動的に収集・送信されます。
          </div>
          <div>
            （５）上記提供者による利用目的：サービス利用状況の分析および改善
          </div>
          <div>
            （６）上記提供者が第三者に情報を提供する意図があるかどうか：なし
          </div>
          <div>
            （7）上記プロバイダのプライバシーポリシーのURL：https://policies.google.com/privacy
          </div>
        </div>
      </div>
      {/* アドモブ */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          アドモブ
        </div>
        <div>
          <div>（１）情報収集モジュールの名称：AdMob</div>
          <div>（２）情報収集モジュールの提供者：Google LLC</div>
          <div>（３）提供されるユーザー情報：端末情報、クッキー等</div>
          <div>
            （４）提供の手段・方法：暗号化通信により自動的に収集・送信されます。
          </div>
          <div>
            （５）上記提供者による利用目的：広告の配信、表示、効果測定のため。
          </div>
          <div>
            （６）上記提供者が第三者に情報を提供する意図があるかどうか：なし
          </div>
          <div>
            （7）上記プロバイダーのプライバシーポリシーのURL：https://sentry.io/privacy
          </div>
        </div>
      </div>
      {/* メタ */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          メタ
        </div>
        <div>
          <div>（１）情報収集モジュールの名称：メタ</div>
          <div>（２）情報収集モジュール提供者：Meta Platforms, Inc.</div>
          <div>
            （３）提供されるユーザー情報：ユーザーの行動データ、デバイス情報等
          </div>
          <div>
            （４）提供の手段・方法：暗号化通信により自動的に収集・送信されます。
          </div>
          <div>
            （５）上記提供者による利用目的：サービス利用状況の分析および改善
          </div>
          <div>
            （６）上記提供者が第三者に情報を提供する意図があるかどうか：なし
          </div>
          <div>
            （7）上記プロバイダーのプライバシーポリシーのURL：https://www.facebook.com/privacy/center
          </div>
        </div>
      </div>
      {/* セントリー */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          セントリー
        </div>
        <div>
          <div>（１）情報収集モジュールの名称：セントリー</div>
          <div>（２）情報収集モジュール提供者：Functional Software, Inc.</div>
          <div>
            （３）提供されるユーザー情報：エラーログ、ユーザーアクション、ブラウザ情報、オペレーティングシステム情報、IPアドレス、デバイス情報、クッキーなど。
          </div>
          <div>
            （４）提供の手段・方法：暗号化通信により自動的に収集・送信されます。
          </div>
          <div>
            （５）上記提供者による利用目的：問題の検出と修正、パフォーマンスの向上。
          </div>
          <div>
            （６）上記提供者が第三者に情報を提供する意図があるかどうか：なし •
          </div>
          <div>
            （7）上記プロバイダーのプライバシーポリシーのURL：https://sentry.io/privacy
          </div>
        </div>
      </div>
      {/* 5  */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          5 個人情報の第三者への提供
        </div>
        <div>
          5-1.
          当社は、以下のいずれかに該当する場合を除き、利用者情報に含まれる個人情報を、利用者の事前の同意を得ることなく、第三者（日本国外にある者を含む）に提供しません。
          <div style={{ padding: "0 0 0 10px" }}>
            <div>
              （１）当社が利用目的の達成に必要な範囲内において、個人情報の全部または一部の取扱いを第三者に委託する場合
            </div>
            <div>
              （２）合併その他の事由による事業の承継に伴って個人情報が提供される場合
            </div>
            <div>
              （３）個人情報は、本条第４項に基づき、関連当事者または情報収集モジュールの提供者に提供される。
            </div>
            <div>
              （4）個人情報保護法（以下「法」という）に基づき当社が情報提供を要求された場合、またはその他の適用法令により当社が情報提供を許容されている場合
            </div>
          </div>
        </div>
        <div>
          5-2.
          当社は、個人情報を第三者に提供する場合、その都度記録を作成し、保管します。
        </div>
        <div>
          5-3.
          当社は、第三者から個人情報の提供を受ける場合には、必要な確認を行うとともに、当該確認に関する記録を作成し、保管します。
        </div>
      </div>
      {/* 6 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          6 セキュリティ対策
        </div>
        <div>
          6-1
          当社は、利用者情報の漏洩、滅失またはき損の防止その他の利用者情報の安全管理のために必要かつ適切な措置を講じます。
        </div>
        <div>
          6-2
          当社は、利用者情報に含まれる個人情報の取扱いを第三者に委託する場合には、当社が定める選定基準を満たす委託先を選定し、契約を締結し、定期的に報告を受け、委託先における個人情報の取扱いを監視します。
        </div>
        <div>
          6-3
          当社が講じる具体的なセキュリティ対策の詳細については、本プライバシーポリシーに記載のお問い合わせ窓口までご連絡ください。
        </div>
      </div>
      {/* 7 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          7 個人情報の開示
        </div>
        <div>
          7-1
          当社は、ユーザーから、個人情報保護法その他の関係法令に基づき、保有個人データの利用目的の通知、開示、第三者への提供の記録の開示、保有個人データの内容の訂正、追加又は削除、利用の停止又は消去、第三者への提供の停止を求められた場合には、ユーザー本人からの求めであることを確認した上で、当社所定の手続きに従い速やかに対応します。
        </div>
        <div>
          7-2
          保有個人データの利用目的の通知および開示または第三者提供の記録の請求については、1件につき1,000円（税抜）の手数料を申し受けます。
        </div>
        <div>
          7-3
          個人情報の開示等の求めの方法等につきましては、本プライバシーポリシーに記載のお問い合わせ窓口までご連絡ください。
        </div>
      </div>
      {/* 8 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          8 個人情報の訂正および利用停止
        </div>
        <div>
          8.1
          当社は、ユーザーから、個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び、個人情報があらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、又は偽りその他不正の手段により収集されたものであるという理由により、個人情報の利用停止を求められた場合には、ユーザー本人からのご請求であることを確認の上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知します。
        </div>
        <div>
          8.2
          当社は、ユーザーから個人情報の削除を求められた場合、その請求に応じる必要があると判断した場合には、ユーザー本人からの請求であることを確認の上で、個人情報を削除し、その旨を通知します。
        </div>
        <div>
          8.3
          個人情報保護法その他の法令により、当社が情報の訂正または利用停止の義務を負わない場合は、第8.1項および第8.2項は適用されません。
        </div>
      </div>
      {/* 9 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          9 連絡先情報
        </div>
        <div>
          本サービスにおける利用者情報の取扱いに関するお問い合わせ、ご質問、苦情等につきましては、下記までご連絡ください。
        </div>
        <div style={{ padding: "0 0 0 10px" }}>
          <div>住所：〒102-0083 東京都中央区銀座６−１２−13銀座大同ビル別館</div>
          <div>会社名：MetaBox株式会社</div>
          <div>代表者：柳 成吉</div>
          <div>個人情報取扱責任者： 柳 成吉</div>
          <div>連絡先メールアドレス: support@metabox.land</div>
        </div>
      </div>
      {/* 10 */}
      <div>
        <div
          style={{ fontWeight: "bold", fontSize: "20px", padding: "20px 0 0" }}
        >
          10 プライバシーポリシーの修正手順
        </div>
        <div>
          当社は、必要に応じて本プライバシーポリシーを変更することがあります。ただし、本プライバシーポリシーを変更する場合に、利用者の同意が法律上必要となるときは、変更後の本プライバシーポリシーは、当社が定める方法により同意した利用者に対してのみ適用されるものとします。当社は、本プライバシーポリシーを変更する場合には、変更後のプライバシーポリシーの効力発生時期および変更内容を、当社ウェブサイトへの掲載その他適切な方法により告知し、または利用者に通知するものとします。
        </div>
        <div>
          本ポリシーは日本語（リンク）で実行されるものとします。日本語が準拠言語であり、本ポリシーの他の言語への翻訳は参照の便宜のみを目的としており、当事者を拘束するものではありません。
        </div>
      </div>
      <div style={{ padding: "20px 0 0" }}>[2024年8月1日処方]</div>
    </div>
  );
}

export default Privacy;
